<!--BoxComponent-->
<template>
  <div
    class="rounded-[3.2rem] py-32 px-[3rem] bg-surface-elevation-1 shadow-[0_.6rem_1.4rem_0_rgba(0,51,150,.07)]"
    :class="{
      'relative min-h-[44rem] flex flex-col !py-[3rem]': businessBank,
      hasHover: isHovered
    }"
    @mouseover="isHovered = true"
    @mouseleave="isHovered = false"
  >
    <slot></slot>
    <a v-if="useLink" :href="targetLink" class="absolute inset-0" @click.prevent="goTo"></a>
  </div>
</template>
<script setup>
import { ref } from 'vue';

import { redirectTo } from '@/utils/common.util';

const props = defineProps({
  businessBank: Boolean,
  useLink: Boolean,
  targetLink: {
    type: String,
    default: ''
  }
});

const isHovered = ref(false);

const goTo = () => {
  if (props.useLink) {
    redirectTo(props.targetLink, { withLocale: false });
  }
};
</script>
